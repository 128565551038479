// eslint-disable-next-line no-unused-vars
const navMobileV2 = (function () {
	const navMobile_mainNav = document.querySelector('.cnf_main-nav');
	if (!navMobile_mainNav || navMobile_mainNav === null || navMobile_mainNav === undefined) {
		return;
	}

	const htmlEl = document.documentElement;
	let navMobile_navIsOpen = false;
	let navMobile_isMobile = !window.matchMedia('(min-width: 980px)').matches;
	const navMobile_mainNavBtns = document.querySelectorAll(
		'.cnf_main-nav .cnf_main-nav__list-item--top-level > button:not(.cnf_main-nav__close-button)'
	);
	let navMobile_currentMainClickedBtn;
	const navMobile_secondLevelBtns = document.querySelectorAll('.cnf_main-nav .cnf_second-level-links--mobile');
	const navMobile_backBtns = document.querySelectorAll('.cnf_main-nav .cnf_back');
	const navMobile_closeBtns = document.querySelectorAll('.cnf_main-nav .cnf_main-nav__close-button--mobile');
	let navMobile_mainNavClassName = '';
	let navMobile_subClassName = '';
	const navMobile_backToTopLevelBtns = document.querySelectorAll('.cnf_main-nav .cnf_back-to-top-level--mobile');

	const navMobile_insuranceMenu = document.querySelector('[data-product="insurance"] .cnf_mobile--nav__mid-menu'); // jshint ignore:line
	const navMobile_insuranceSecondMenu = document.querySelector(
		'[data-product="insurance"] .cnf_main-nav__container--mid-level'
	); // jshint ignore:line
	const navMobile_utilsMenu = document.querySelector('[data-product="utilities"] .cnf_mobile--nav__mid-menu'); // jshint ignore:line
	const navMobile_utilsSecondMenu = document.querySelector(
		'[data-product="utilities"] > .cnf_main-nav__container--bottom-level'
	); // jshint ignore:line
	const navMobile_financeMenu = document.querySelector('[data-product="finance"] .cnf_mobile--nav__mid-menu'); // jshint ignore:line
	const navMobile_financeSecondMenu = document.querySelector(
		'[data-product="finance"] > .cnf_main-nav__container--bottom-level'
	); // jshint ignore:line
	const navMobile_carCareMenu = document.querySelector(
		'[data-product="car-care-_-selling"] .cnf_mobile--nav__mid-menu'
	); // jshint ignore:line
	const navMobile_carCareSecondMenu = document.querySelector(
		'[data-product="car-care-_-selling"] > .cnf_main-nav__container--bottom-level'
	); // jshint ignore:line
	let noHeightElArr = [];
	let prevScrollpos = window.pageYOffset;

	const tabbableEls = document.querySelectorAll('[data-no-tab-mobile]');
	let buttonText = '';

	// Set all data-buttontext
	document.querySelectorAll('[data-buttontext]').forEach(function (button) {
		let buttonText = button.textContent.trim().replace(/\s+/g, '-').toLowerCase();
		let productEl;
		let subProductEl;
		let subProductText = '';
		let productText = '';
		if (buttonText === 'tools-&-tips') {
			subProductEl = button.closest('[data-sub-product]');
			if (subProductEl) {
				subProductText = subProductEl.dataset.subProduct;
				buttonText = `tools--${subProductText}`;
			} else {
				productEl = button.closest('[data-product]');
				if (productEl) {
					productText = productEl.dataset.product;
					buttonText = `tools--${productText}`;
				}
			}
		}
		button.dataset.buttontext = buttonText;
	});

	document.addEventListener('DOMContentLoaded', function () {
		setAllTabIndexes();
	});

	// Set isMobile on browser resize
	window.addEventListener('resize', function () {
		if (window.matchMedia('(min-width: 980px)').matches) {
			let elemClassList;
			// TEST IF IT ALREADY HAS NAV-PRODUCT--BLAH THEN USE THE CLASSLIST TO REPOPULATE AFTER RESIZE OTHERWIZE BLITZ ALL BUT MAIN-NAV CLASS
			if (/nav-product--/.test(navMobile_mainNav.classList)) {
				elemClassList = navMobile_mainNav.classList;
			} else {
				elemClassList = 'cnf_main-nav';
				window._navDesktopV2.closeNav();
			}

			navMobile_isMobile = false;
			navMobile_mainNav.className = elemClassList;
			htmlEl.classList.remove('cnf_nav-is-open');
			document.querySelectorAll('.cnf_main-nav .cnf_active').forEach(function (activeEl) {
				activeEl.classList.remove('cnf_active');
			});
			navMobile_navIsOpen = false;
		} else {
			navMobile_isMobile = true;
		}
		setAllTabIndexes();
	});

	window.addEventListener('scroll', function () {
		if (!navMobile_isMobile) {
			return;
		}
		if (navMobile_navIsOpen) {
			return;
		}
		const currentScrollPos = window.pageYOffset;
		if (prevScrollpos > currentScrollPos || window.pageYOffset < 50) {
			navMobile_mainNav.classList.remove('cnf_unpinned');
		} else {
			navMobile_mainNav.classList.add('cnf_unpinned');
		}
		prevScrollpos = currentScrollPos;
	});

	document.querySelector('header').addEventListener('keydown', function (event) {
		if (!navMobile_isMobile) {
			return;
		}
		if (event.code === 'Tab' && !event.shiftKey) {
			const focusableEl = navMobile_mainNav.querySelectorAll(
				'[data-product="car-care-_-selling"] .cnf_main-nav__container--bottom-level [tabindex="0"]'
			); // jshint ignore:line
			const carCareBtn = document.querySelector('[data-product="car-care-_-selling"] > button'); // jshint ignore:line
			if (
				document.activeElement === carCareBtn &&
				document.querySelector('[data-product="car-care-_-selling"].cnf_active') === null &&
				navMobile_navIsOpen
			) {
				// jshint ignore:line
				// focus is on car care main button and car care menu is not open
				event.preventDefault();
				document.querySelector('.cnf_active .cnf_main-nav__close-button--mobile').focus();
			} else if (document.activeElement === focusableEl[focusableEl.length - 1]) {
				// focus is on last link of any option within care care menu
				event.preventDefault();
				document.querySelector('[data-product="insurance"] > button').focus(); // jshint ignore:line
			}
		} else if (event.code === 'Tab' && event.shiftKey) {
			if (document.activeElement.classList.contains('cnf_logo') && navMobile_navIsOpen) {
				// Logo has focus
				event.preventDefault();
				const tabbable = document.querySelectorAll(
					".cnf_main-nav [tabindex='0']:not(.cnf_main-nav__close-button)"
				);
				tabbable[tabbable.length - 1].focus();
			}
		}
	});

	document.getElementById('skip-nav-link').addEventListener('click', function () {
		closeMobileNav();
	});

	for (let i = 0; i < navMobile_mainNavBtns.length; i++) {
		navMobile_mainNavBtns[i].addEventListener('click', function (event) {
			// jshint ignore:line
			if (!navMobile_isMobile) {
				return;
			}
			navMobile_navIsOpen = true;
			resetNoHeightClasses();
			onMainNavClick_mobile(event);
			setActiveTabIndexes(event, true);
		});
	}

	for (let i = 0; i < navMobile_secondLevelBtns.length; i++) {
		navMobile_secondLevelBtns[i].addEventListener('click', function (event) {
			// jshint ignore:line
			if (!navMobile_isMobile) {
				return;
			}
			onSecondaryLevelClick(event);
			setActiveTabIndexes(event, false);
		});
	}

	for (let i = 0; i < navMobile_closeBtns.length; i++) {
		navMobile_closeBtns[i].addEventListener('click', function () {
			// jshint ignore:line
			closeMobileNav();
		});
	}

	for (let i = 0; i < navMobile_backBtns.length; i++) {
		navMobile_backBtns[i].addEventListener('click', function (event) {
			// jshint ignore:line
			if (!navMobile_isMobile) {
				return;
			}
			backOneMenuLayer();
			setAllTabIndexes();
			// set tabIndex = 0 on the menu that is left open
			handleTabbingAfterBackPressed(event);
		});
	}

	for (let i = 0; i < navMobile_backToTopLevelBtns.length; i++) {
		navMobile_backToTopLevelBtns[i].addEventListener('click', function () {
			// jshint ignore:line
			if (!navMobile_isMobile) {
				return;
			}
			document.querySelectorAll('.cnf_main-nav .cnf_active').forEach(function (activeMenu) {
				if (!activeMenu.classList.contains('cnf_main-nav__list-item--top-level')) {
					activeMenu.classList.remove('cnf_active');
				}
			});
			setAllTabIndexes();
			document
				.querySelectorAll('.cnf_main-nav__container--mid-level--ul > li > button')
				.forEach(function (button) {
					button.tabIndex = 0;
				});
			document.querySelector('[data-product="insurance"] .cnf_main-nav__close-button--mobile').tabIndex = 0; // jshint ignore:line
			document.querySelector('[data-sub-product="motor-primary"] > button').focus(); // jshint ignore:line
			// add no-height classes back
			setTimeout(function () {
				noHeightElArr.forEach(function (el) {
					el.classList.add('cnf_no-height');
				});
				noHeightElArr = [];
			}, 251);
		});
	}

	function setAllTabIndexes() {
		if (navMobile_isMobile) {
			tabbableEls.forEach(function (element) {
				element.tabIndex = -1;
			});
		} else {
			tabbableEls.forEach(function (element) {
				element.tabIndex = 0;
			});
		}
	}

	function setActiveTabIndexes(event, isFromTopMenu) {
		let closeButton;
		let activeTabbableEls;
		tabbableEls.forEach(function (element) {
			element.tabIndex = -1;
		});
		if (isFromTopMenu) {
			switch (true) {
				case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'insurance':
					activeTabbableEls = document.querySelectorAll(
						'[data-product="insurance"] .cnf_main-nav__container--mid-level [data-no-tab-mobile]:not(.cnf_main-nav__container--bottom-level [data-no-tab-mobile]:not(.cnf_main-nav__container--mid-level--li-close [data-no-tab-mobile])'
					); // jshint ignore:line
					closeButton = document.querySelector(
						'[data-product="insurance"] .cnf_mobile--nav__mid-menu button'
					); // jshint ignore:line
					break;
				case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'utilities':
					activeTabbableEls = document.querySelectorAll(
						'[data-product="utilities"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
					); // jshint ignore:line
					closeButton = document.querySelector(
						'[data-product="utilities"] .cnf_mobile--nav__mid-menu button'
					); // jshint ignore:line
					break;
				case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'finance':
					activeTabbableEls = document.querySelectorAll(
						'[data-product="finance"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
					); // jshint ignore:line
					closeButton = document.querySelector('[data-product="finance"] .cnf_mobile--nav__mid-menu button'); // jshint ignore:line
					break;
				case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'car-care-_-selling':
					activeTabbableEls = document.querySelectorAll(
						'[data-product="car-care-_-selling"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
					); // jshint ignore:line
					closeButton = document.querySelector(
						'[data-product="car-care-_-selling"] .cnf_mobile--nav__mid-menu button'
					); // jshint ignore:line
					break;
			}
		} else {
			if (
				(event.target.classList.contains('cnf_second-level-links--mobile') &&
					event.target.classList.contains('cnf_main-nav__options--mid-level')) ||
				event.target.parentElement.dataset.product === 'insurance'
			) {
				activeTabbableEls = event.target.nextElementSibling.querySelectorAll(
					'[data-no-tab-mobile]:not(.cnf_main-nav__options--bottom-level > div [data-no-tab-mobile]):not(.cnf_main-nav__articles-container [data-no-tab-mobile]'
				);
			} else {
				activeTabbableEls = event.target.nextElementSibling.querySelectorAll('[data-no-tab-mobile]');
			}
			const parentLi = event.target.closest('.cnf_main-nav__list-item--top-level');
			closeButton = parentLi.querySelector('.cnf_main-nav__close-button--mobile');
		}
		activeTabbableEls.forEach(function (element) {
			element.tabIndex = 0;
		});
		closeButton.tabIndex = 0;
		// Focus the right button
		setTimeout(function () {
			activeTabbableEls[0].focus();
		}, 251);
	}

	function handleTabbingAfterBackPressed(event) {
		let closeButton;
		let activeTabbableEls;
		let elToFocus;
		switch (true) {
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'insurance':
				if (event.target.classList.contains('cnf_back-to-insurance')) {
					activeTabbableEls = document.querySelectorAll(
						'[data-product="insurance"] .cnf_main-nav__container--mid-level [data-no-tab-mobile]:not(.cnf_main-nav__container--bottom-level [data-no-tab-mobile]:not(.cnf_main-nav__container--mid-level--li-close [data-no-tab-mobile])'
					); // jshint ignore:line
					let product = event.target.innerText.toLowerCase();
					if (product === 'motor' || product === 'business') {
						product = `${product}-primary`;
					}
					elToFocus = document.querySelector(`[data-sub-product="${product}"] > button`); // jshint ignore:line
				} else {
					elToFocus = document.querySelector(
						`[data-product="insurance"] .cnf_main-nav__container--mid-level [data-buttontext="${buttonText}"]`
					); // jshint ignore:line
					activeTabbableEls = elToFocus
						.closest('.cnf_main-nav__container--bottom-level--ul')
						.querySelectorAll(
							'[data-no-tab-mobile]:not(.cnf_main-nav__options--bottom-level > div [data-no-tab-mobile]):not(.cnf_main-nav__articles-container [data-no-tab-mobile]'
						);
				}
				closeButton = document.querySelectorAll(
					'[data-product="insurance"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
				); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'utilities':
				activeTabbableEls = document.querySelectorAll(
					'[data-product="utilities"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
				); // jshint ignore:line
				closeButton = document.querySelector('.cnf_active .cnf_main-nav__close-button--mobile');
				elToFocus = document.querySelector(`[data-product="utilities"] [data-buttontext="${buttonText}"]`); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'finance':
				activeTabbableEls = document.querySelectorAll(
					'[data-product="finance"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
				); // jshint ignore:line
				closeButton = document.querySelector('.cnf_active .cnf_main-nav__close-button--mobile');
				elToFocus = document.querySelector(`[data-product="finance"] [data-buttontext="${buttonText}"]`); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'car-care-_-selling':
				activeTabbableEls = document.querySelectorAll(
					'[data-product="car-care-_-selling"] .cnf_main-nav__container--bottom-level .cnf_second-level-links--mobile'
				); // jshint ignore:line
				closeButton = document.querySelector('.cnf_active .cnf_main-nav__close-button--mobile');
				elToFocus = elToFocus = document.querySelector(
					`[data-product="car-care-_-selling"] [data-buttontext="${buttonText}"]`
				); // jshint ignore:line
				break;
		}
		closeButton.tabIndex = 0;
		activeTabbableEls.forEach(function (element) {
			element.tabIndex = 0;
		});
		elToFocus.focus();
	}

	function onMainNavClick_mobile(event) {
		if (!navMobile_isMobile) {
			return;
		}
		navMobile_currentMainClickedBtn = event.target;
		htmlEl.classList.add('cnf_nav-is-open');
		navMobile_openSecondaryMenu();
	}

	function onSecondaryLevelClick(event) {
		if (!navMobile_isMobile) {
			return;
		}
		const button = event.target;
		buttonText = button.dataset.buttontext;
		if (navMobile_subClassName !== '') {
			navMobile_mainNav.classList.remove(navMobile_subClassName);
		}
		navMobile_subClassName = `${navMobile_mainNavClassName}--${buttonText}`;
		removeNoHeightClasses(event);
		navMobile_mainNav.classList.add(navMobile_subClassName);

		const activeDiv = button.nextElementSibling;
		if (activeDiv) {
			activeDiv.classList.add('cnf_active');
		}

		scrollTopAllMenus();
	}

	function scrollTopAllMenus() {
		if (!navMobile_isMobile) {
			return;
		}
		const midMenu = document.querySelector('.cnf_main-nav__container--mid-level--ul');
		const bottomMenus = document.querySelectorAll('.cnf_main-nav__container--bottom-level--ul');
		const toolsMenus = document.querySelectorAll('.cnf_main-nav__articles-container');
		midMenu.scrollTop = 0;
		bottomMenus.forEach(function (el) {
			el.scrollTop = 0;
		});
		toolsMenus.forEach(function (el) {
			el.scrollTop = 0;
		});
	}

	function navMobile_openSecondaryMenu() {
		if (!navMobile_isMobile) {
			return;
		}
		document.querySelectorAll('.cnf_main-nav .cnf_active').forEach(function (activeEl) {
			activeEl.classList.remove('cnf_active');
		});
		switch (true) {
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'insurance':
				navMobile_mainNavClassName = 'cnf_nav--is-open-mobile--insurance';
				navMobile_insuranceMenu.classList.remove('cnf_no-height');
				navMobile_insuranceSecondMenu.classList.remove('cnf_no-height');
				document.querySelector('[data-product="insurance"]').classList.add('cnf_active'); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'utilities':
				navMobile_mainNavClassName = 'cnf_nav--is-open-mobile--utilities';
				navMobile_utilsMenu.classList.remove('cnf_no-height');
				navMobile_utilsSecondMenu.classList.remove('cnf_no-height');
				document.querySelector('[data-product="utilities"]').classList.add('cnf_active'); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'finance':
				navMobile_mainNavClassName = 'cnf_nav--is-open-mobile--finance';
				navMobile_financeMenu.classList.remove('cnf_no-height');
				navMobile_financeSecondMenu.classList.remove('cnf_no-height');
				document.querySelector('[data-product="finance"]').classList.add('cnf_active'); // jshint ignore:line
				break;
			case navMobile_currentMainClickedBtn.parentElement.dataset.product === 'car-care-_-selling':
				navMobile_mainNavClassName = 'cnf_nav--is-open-mobile--car-care';
				navMobile_carCareMenu.classList.remove('cnf_no-height');
				navMobile_carCareSecondMenu.classList.remove('cnf_no-height');
				document.querySelector('[data-product="car-care-_-selling"]').classList.add('cnf_active'); // jshint ignore:line
				break;
			default:
				return;
		}
		navMobile_mainNav.className = `cnf_main-nav cnf_nav--is-open-mobile ${navMobile_mainNavClassName}`;
	}

	function backOneMenuLayer() {
		if (!navMobile_isMobile) {
			return;
		}
		if (noHeightElArr.length > 0) {
			const menuToClose = noHeightElArr[noHeightElArr.length - 1];
			menuToClose.classList.remove('cnf_active');
			setTimeout(function () {
				menuToClose.classList.add('cnf_no-height');
			}, 251);
			noHeightElArr.pop();
		}
	}

	// Observer to add faders to mobile menu
	const observer = new IntersectionObserver(
		function (entries) {
			entries.forEach(function (entry) {
				addOrRemoveFader(entry);
			});
		},
		{ threshold: 1 }
	);

	[
		document.querySelector('[data-product="insurance"] > button'),
		document.querySelector('[data-product="car-care-_-selling"] > button'),
	].forEach(function (btnEl) {
		// jshint ignore:line
		observer.observe(btnEl);
	});

	function addOrRemoveFader(element) {
		if (!navMobile_isMobile) {
			return;
		}
		const elId = element.target;
		elId.closest('[data-product]').dataset.product === 'insurance'; // jshint ignore:line
		const isIntersecting = element.isIntersecting;
		const navUl = document.querySelector('.cnf_main-nav__container');
		if (elId.closest('[data-product]').dataset.product === 'insurance') {
			// jshint ignore:line
			if (isIntersecting) {
				navUl.classList.remove('cnf_first-option-off-screen');
			} else {
				navUl.classList.add('cnf_first-option-off-screen');
			}
		} else {
			if (isIntersecting) {
				navUl.classList.remove('cnf_last-option-off-screen');
			} else {
				navUl.classList.add('cnf_last-option-off-screen');
			}
		}
	}

	function removeNoHeightClasses(event) {
		if (!navMobile_isMobile) {
			return;
		}
		const button = event.target;
		const noHeightEl = button.nextElementSibling;
		noHeightEl.classList.remove('cnf_no-height');
		if (!noHeightElArr.includes(noHeightEl)) {
			noHeightElArr.push(noHeightEl);
		}
	}

	function closeMobileNav() {
		if (!navMobile_isMobile) {
			return;
		}
		navMobile_navIsOpen = false;
		document.querySelectorAll('.cnf_main-nav .cnf_active').forEach(function (activeEl) {
			activeEl.classList.remove('cnf_active');
		});
		setTimeout(function () {
			htmlEl.classList.remove('cnf_nav-is-open');
			resetNoHeightClasses();
			setAllTabIndexes();
		}, 251);
	}

	function resetNoHeightClasses() {
		if (!navMobile_isMobile) {
			return;
		}
		const menus = [
			navMobile_insuranceMenu,
			navMobile_insuranceSecondMenu,
			navMobile_utilsMenu,
			navMobile_utilsSecondMenu,
			navMobile_financeMenu,
			navMobile_financeSecondMenu,
			navMobile_carCareMenu,
			navMobile_carCareSecondMenu,
		];
		menus.forEach(function (menu) {
			menu.classList.add('cnf_no-height');
		});
		noHeightElArr.forEach(function (el) {
			el.classList.add('cnf_no-height');
		});
		noHeightElArr = [];
	}
})();
